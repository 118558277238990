/** @jsxImportSource theme-ui */
import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import { Container, Flex, Heading, Text, Link as ThemeUILink, Grid, Box, Button } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faMapLocationDot, faClock } from "@fortawesome/free-solid-svg-icons";
import ThemedMarkdown from "../components/themed-markdown";
import { GatsbyImage, getImage, ImageDataLike, StaticImage } from "gatsby-plugin-image";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import theme from "../gatsby-plugin-theme-ui";
import { InternalButtonLink, ExternalButtonLink } from "../components/nav-link";

type Employee = {
  name: string;
  image?: ImageDataLike;
  title: string;
  description: string;
};

const EmployeeShort = ({ name, image, title, description }: Employee) => {
  const imageData = getImage(image);
  return (
    <Flex sx={{ flexDirection: "column", mt: [4, 2, 2] }}>
      <GatsbyImage image={imageData} alt={`Profilbild ${name}`} />
      <Heading as="h3" variant="styles.h6" mb={0} mt={2}>
        {name}
      </Heading>
      <Text variant="smallTextPlus">{title}</Text>
      <div
        sx={{
          borderImageSource: (theme) =>
            `linear-gradient(to right, ${theme.colors.primary} 25%, rgba(226, 0, 116, 0.0))`,
          borderImageSlice: 1,
          width: "100%",
          mb: 3,
          borderTopWidth: "2px",
          borderColor: "primary",
          borderTopStyle: "solid",
        }}
      />
      <Text>{description}</Text>
    </Flex>
  );
};

const EmployeeEmpty = () => {
  return (
    <Flex sx={{ flexDirection: "column", mt: [4, 2, 2] }}>
      <StaticImage src="../img/silhouette.jpg" alt="Silhouette" width={500} placeholder="blurred" />
      <div
        sx={{
          backgroundColor: "#767676",
          borderRadius: "16px",
          width: "160px",
          height: "16px",
          mt: "14px",
          mb: "5px",
        }}
      />
      <div
        sx={{
          backgroundColor: "#e6e6e6",
          borderRadius: "16px",
          width: "120px",
          height: "11px",
          mt: "5px",
          mb: "7px",
        }}
      />
      <div
        sx={{
          borderImageSource: (theme) =>
            `linear-gradient(to right, ${theme.colors.primary} 25%, rgba(226, 0, 116, 0.0))`,
          borderImageSlice: 1,
          width: "100%",
          mb: 3,
          borderTopWidth: "2px",
          borderColor: "primary",
          borderTopStyle: "solid",
        }}
      />
      <Text>
        Hier könnte Ihr Profil sein. Einfach Bewerben unter <Link to="/karriere">Karriere</Link>.
      </Text>
    </Flex>
  );
};

type StorePageProps = {
  data: {
    markdownRemark: any;
  };
  location: string;
};

const StorePage = ({ data, location }: StorePageProps) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      activeNav="store"
      seo={{ title: frontmatter.name, description: frontmatter.seoDescription, pathname: location }}
    >
      <Container mt={5}>
        <Heading as="h1" variant="styles.h2" mb={4}>
          Gute Hörgeräte in {frontmatter.name}
        </Heading>
        <Flex sx={{ flexWrap: "wrap", flexDirection: ["column", "column", "row"] }}>
          {/* Slider */}
          <Box sx={{ flex: "3 3 0" }} mr={[0, 0, 2]}>
            <Carousel autoPlay infiniteLoop showThumbs={false} showStatus={false}>
              {frontmatter.slider.map((slider) => {
                const imageData = getImage(slider.image);
                return (
                  <GatsbyImage
                    key={slider.image.childImageSharp.gatsbyImageData.images.fallback.src}
                    image={imageData}
                    alt={slider.alttext || ""}
                  />
                );
              })}
            </Carousel>
            <Box sx={{ background: (theme) => theme.colors.backgroundOff, padding: 4, mt: 3 }}>
              <Text>"{frontmatter.quote}"</Text>
              <Text variant="smallText"> - {frontmatter.quoteBy}</Text>
            </Box>
          </Box>
          <Box sx={{ flex: "2 2 0" }} ml={[0, 0, 2]}>
            <ThemedMarkdown html={frontmatter.description} />
          </Box>
        </Flex>
      </Container>
      <Container mt={[5, 5, 6]}>
        <Heading as="h2" variant="styles.h2" mb={4}>
          Wir freuen uns auf Sie!
        </Heading>
        <Flex sx={{ flexDirection: ["column", "column", "row"] }} mb={4}>
          <Flex
            sx={{
              flexDirection: "column",
              flex: "1 1 0",
              alignContent: "center",
              textAlign: "center",
              mb: [5, 4, 0],
            }}
          >
            <FontAwesomeIcon size="3x" sx={{ color: "primary" }} icon={faMapLocationDot} />
            <Heading as="h3" variant="styles.h5" mt={4}>
              Adresse
            </Heading>
            <Text as="address" sx={{ fontStyle: "normal" }}>
              OTT hören {frontmatter.name}
              <br />
              {frontmatter.address.street}
              <br />
              {frontmatter.address.plz} {frontmatter.address.city}
              <br />
              <ThemeUILink href={frontmatter.address.navlink} target="_blank">
                Google Navigation
              </ThemeUILink>
            </Text>
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              flex: "1 1 0",
              alignContent: "center",
              textAlign: "center",
              mb: [5, 4, 0],
            }}
          >
            <FontAwesomeIcon size="3x" sx={{ color: "primary" }} icon={faComments} />
            <Heading as="h3" variant="styles.h5" mt={4}>
              Kommunikation
            </Heading>
            <Text as="address" sx={{ fontStyle: "normal" }}>
              Tel.:{" "}
              <ThemeUILink href={`tel:${frontmatter.communication.normtel}`} target="_blank">
                {frontmatter.communication.tel}
              </ThemeUILink>
              <br />
              {frontmatter.communication.normmobile && frontmatter.communication.mobile && (
                <React.Fragment>
                  Mobil u Whatsapp.:{" "}
                  <ThemeUILink href={`tel:${frontmatter.communication.normmobile}`} target="_blank">
                    {frontmatter.communication.mobile}
                  </ThemeUILink>
                  <br />
                </React.Fragment>
              )}
              <ThemeUILink
                href={`https://wa.me/${frontmatter.communication.normtel.slice(1)}`}
                target="_blank"
              >
                Whatsapp
              </ThemeUILink>
              <br />
              Fax.: {frontmatter.communication.fax}
              <br />
              Email:{" "}
              <ThemeUILink href={`mailto:${frontmatter.communication.email}`} target="_blank">
                {frontmatter.communication.email}
              </ThemeUILink>
            </Text>
          </Flex>
          <Flex
            sx={{
              flexDirection: "column",
              flex: "1 1 0",
              alignContent: "center",
              textAlign: "center",
              mb: 0,
            }}
          >
            <FontAwesomeIcon size="3x" sx={{ color: "primary" }} icon={faClock} />
            <Heading as="h3" variant="styles.h5" mt={4}>
              Öffnungszeiten
            </Heading>
            <Text>
              {frontmatter.openinghours.map((lineobj, i) => (
                <React.Fragment key={i}>
                  {lineobj.line}
                  <br />
                </React.Fragment>
              ))}
            </Text>
          </Flex>
        </Flex>
        <Box mt={[3, 3, 4]}>
          <InternalButtonLink to="/">Hier Online Termin vereinbaren</InternalButtonLink>
        </Box>
      </Container>
      <Container mt={[5, 5, 6]}>
        <Heading as="h2" variant="styles.h2" mb={4}>
          Unsere Hörexperten
        </Heading>
        <Grid gap={[3, 4, 5]} columns={[1, 2, 3]} sx={{ mb: 6 }}>
          {frontmatter.employees.map((e) => (
            <EmployeeShort key={e.name} {...e} />
          ))}
          <EmployeeEmpty />
        </Grid>
      </Container>
      <Container my={[3, 4, 5]} sx={{ display: "flex", justifyContent: "center" }}>
        <ExternalButtonLink to={frontmatter.reviewLink}>
          Bewerten Sie uns auf Google!
        </ExternalButtonLink>
      </Container>
    </Layout>
  );
};

export default StorePage;

export const pageQuery = graphql`
  query StorePageById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        slider {
          image {
            childImageSharp {
              gatsbyImageData(width: 1500, quality: 90, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          alttext
        }
        quote
        quoteBy
        description
        seoDescription
        employees {
          name
          description
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 400, quality: 92, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
        address {
          street
          plz
          city
          navlink
        }
        communication {
          tel
          normtel
          mobile
          normmobile
          fax
          email
        }
        openinghours {
          line
        }
        reviewLink
      }
    }
  }
`;
